export const hideElementById = (id) =>
{
    document.getElementById(id).style.display = "none";
}

export const showElementById = (id, display) =>
{
    display = display ? display : "block"
    document.getElementById(id).style.display = display;
}

export const hideElementsByClassName = (className) =>
{
    const elements = document.getElementsByClassName(className)

    for(let i = 0; i < elements.length; i++)
    {
        elements[i].style.display = "none"
    }
}

export const toggleElementById = (id) =>
{
    if(document.getElementById(id).style.display == "none")
    {
        document.getElementById(id).style.display = "block"
    }else{
        document.getElementById(id).style.display = "none"
    }
}

export const addClassToId = (id, classToAdd) =>
    {
        document.getElementById(id).classList.add(classToAdd);
    }

export const removeClassFromId = (id, classToRemove) =>
    {
        document.getElementById(id).classList.remove(classToRemove);
    }

export const addClassToClassName = (className, classToAdd) =>
{
    const elements = document.getElementsByClassName(className)

    for(let i = 0; i < elements.length; i++)
    {
        elements[i].classList.add(classToAdd);
    }
}

export const removeClassFromClassName = (className, classToRemove) =>
{
    const elements = document.getElementsByClassName(className)

    for(let i = 0; i < elements.length; i++)
    {
        elements[i].classList.remove(classToRemove);
    }
}