export default function HowDoesThisWork()
{
    return (
        <>
            <div className="support-questions-header">How does this work?</div>
            <article className="support-questions-solution">
                <p >Some may ask "how"? How are the prices so affordable?</p>
                <section>
                    <h3 className="support-answers-section-header">1. Wholesale</h3>
                    <p>Most of our products are purchased through wholesale. To put it simple, we purchase our products in huge quantities which allow us to get extreme discounts from the different companies.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">2. Reselling</h3>
                    <p>Some of our products are sold through reselling. We help our players who want to quit the game they are playing by purchasing their accounts that they will eventually disgard. This allows us to buy some accounts at a price lower than we sell and allows us to resell them to you for the most affordable prices.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">3. Advertising</h3>
                    <p>Game developers contact us to help advertise their games. In return they offer us discounts to licenses for their games and allow us to resell their games so that it can gain more attention. This not only helps the customers, but helps starting game developers to launch publicize their products.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Conclusion</h3>
                    <p>All our products not only benefit us, but benefit players, companies and developers all around the world. We strive to give everyone an opportunies to enter the world of gaming.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}