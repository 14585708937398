import { RouteConstants } from "../constants/AppConstants"
import { setLocalStorageItem } from "../util/localstorage";
import api from "./apiConfig"

export const getShoppingCart = async () =>
{
    try
    {
        const response = await api.get(`${RouteConstants.SHOPPING_CART_URL}/get`)

        if(response.headers.scid)
            setLocalStorageItem("SCID", response.headers.scid);

        return response;
    }catch(err){
        try{
            const response = await api.get(`${RouteConstants.SHOPPING_CART_URL}/get`)
            return response;
        }catch(error){
            return error.response
        }
    }
}

export const deleteShoppingCartItemById = async (shoppingCartItemId) =>
{
    try
    {
        const response = await api.delete(`${RouteConstants.SHOPPING_CART_URL}/delete/${shoppingCartItemId}`)
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const addItemToShoppingCart = async (shoppingCartItemInfo) =>
{
    try
    {
        const response = await api.post(`${RouteConstants.SHOPPING_CART_URL}/add`, shoppingCartItemInfo)

        if(response.headers.scid)
            setLocalStorageItem("SCID", response.headers.scid);

        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}

export const updateShoppingCartItem = async (shoppingCartItemId, shoppingCartItemInfo) => {
    try
    {
        const response = await api.put(`${RouteConstants.SHOPPING_CART_URL}/shoppingCartItem/${shoppingCartItemId}`, shoppingCartItemInfo);
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}