import React, { createContext, useContext, useState } from "react";

export const ShoppingCartItemsContext = createContext();

export function useShoppingCart()
{
    return useContext(ShoppingCartItemsContext)
}

export const ShoppingCartItemProvider = ({children}) => {
    const [ shoppingCartItemCount, setShoppingCartItemCount ] = useState(0)

    return (
    <ShoppingCartItemsContext.Provider value={{shoppingCartItemCount, setShoppingCartItemCount}}>
        { children }
    </ShoppingCartItemsContext.Provider>
    )
}