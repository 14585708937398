import React, { createContext, useContext, useState } from "react";

import { setCookie } from "../util/cookies.js";

import { getCurrencyRate } from "../services/currency.js"

export const CurrencyContext = createContext();

export function useCurrency()
{
    return useContext(CurrencyContext)
}

export const CurrencyProvider = ({children}) => {
    const [ currency, setCurrency ] = useState({
        symbol: "$",
        currency: "USD",
        rate: 1.0
    })

    const changeCurrency = async (cur) => 
    {
        const response = await getCurrencyRate(cur);

        const tempCurrency = {};
    
        // Set the actual currency
        tempCurrency.currency = cur;
    
        // Set the rate
        tempCurrency.rate = response.message;
    
        // Set the symbol
        if(cur === "USD" || cur === "NZD" || cur == "CAD") tempCurrency.symbol = "$";
        else if(cur === "EUR") tempCurrency.symbol = "€";
        else if(cur === "JPY") tempCurrency.symbol = "¥";
        else if(cur === "GBP") tempCurrency.symbol = "£";
    
        setCookie("currency", cur, 30)
        setCurrency({...tempCurrency})
    
        document.getElementById("n-b-top-up").style.display = "inline-block";
        document.getElementById("n-b-top-down").style.display = "none";
        document.getElementById('currency-selector').style.display = "none";
    }

    return (
    <CurrencyContext.Provider value={ {currency, changeCurrency} }>
        { children }
    </CurrencyContext.Provider>
    )
}