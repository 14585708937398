import api from "./apiConfig";
import { RouteConstants } from "../constants/AppConstants";
import { deleteCookie, getCookie } from "../util/cookies";

export const getUser = async () =>
{
  try{
    const response = await api.get(RouteConstants.USERS_URL)
    return response.data;
  }catch(error){
    throw error;
  }
}

export const loginUser = async (userData) =>
{
    try{
      const response = await api.get(`${RouteConstants.LOGIN_API_URL}`, userData);
      return response;
    }catch (err) {
      console.log(err)
      return err.response;
    }
}

export const registerMember = async (data) =>
{
  try{
    const response = await api.post(`${RouteConstants.REGISTER_URL}/member`, data)
    return response;
  }catch(err){
    console.log(err)
    return err.response;
  }
}

export const sendVerificationLink = async (email) => {
  try{
    const RTOK = getCookie("RTOK");
    console.log(RTOK)

    const response = await api.post(`${RouteConstants.REGISTER_URL}/verificationlink?email=${email}`)
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}

export const verifyEmail = async (email, token) => {
  try{
    const response = await api.get(`${RouteConstants.VERIFY_URL}?email=${email}&token=${token}`)

    if(response.status === 201)
      deleteCookie("RTOK");
    
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}

/**
 * Gets user information based on the UTOK token stored
 * 
 * @returns Response
 */
export const getUserInfo = async () => {
  try{
    const response = await api.get(`${RouteConstants.USERS_URL}/info`);
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}