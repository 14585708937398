import { AppConstants, RouteConstants } from "../constants/AppConstants";
import api from "./apiConfig";

export const createDiscordInviteLink = async (location) => {
    try{
        const response = await api.get(`${RouteConstants.DISCORD_URL}/invitelink?location=${location}`);
        return window.open(response.data, "_blank");
    }catch(err){
        console.log(err);
        return window.open(AppConstants.DISCORD_REGULAR_INVITE, "_blank");
    }
}