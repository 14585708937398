import axios from "axios";
import { AppConstants, RouteConstants } from "../constants/AppConstants";
import { deleteCookie, getCookie, setCookie } from "../util/cookies";
import { getLocalStorageItem, setLocalStorageItem } from "../util/localstorage";

const api = axios.create({
  baseURL: AppConstants.BASE_URL,
  validateStatus: (s) => s >= 200 && s < 400
});

/**
 * Intercept request to the server
 */
api.interceptors.request.use(
  request => {
    // Check if the request is to login or a authorization request
    const authorization = getCookie("UTOK") ? getCookie("UTOK") : getCookie("RTOK")

    if(getLocalStorageItem("SCID"))
    {
      request.headers.scid = getLocalStorageItem("SCID");
    }

    if(request.url === RouteConstants.LOGIN_API_URL && request.email && request.password)
    {
      request.headers.Authorization = "Basic " + window.btoa(request.email + ':' + request.password);
    // If the user is not registering or browsing a store then add the Authorization header
    }else if(authorization){
      request.headers.Authorization = authorization;
    }
    return request
  }
)

/**
 * Intercept responses from the server
 */
api.interceptors.response.use(
  response => {
    if(response.headers.scid)
      setLocalStorageItem("SCID", response.headers.scid);

    if(response.headers.utok_status && response.headers.utok_status === "INVALID")
    {
      deleteCookie("UTOK")
      window.location.reload()
    }

    const responseCookies = response.headers.get("Set-Cookie");
    
    if(responseCookies)
    {
      responseCookies.split(";").forEach(cookie => {
        setCookie(cookie.split("=")[0], cookie.split("=")[1])
      })
    }
    return response;
  },

  async (error) =>
  {
    const response = error.response;
    if(response.headers.scid)
      setLocalStorageItem("SCID", response.headers.scid);

    if(error.response.headers.utok_status && error.response.headers.utok_status === "INVALID")
    {
      deleteCookie("UTOK")
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default api;