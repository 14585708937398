import "./hoverFooter.css"

import Discord from "../../images/discord.webp"
import { AppConstants } from "../../constants/AppConstants";
import { createDiscordInviteLink } from "../../services/discord";

function HoverFooter()
{
    return (
        <div className="hover-footer">
            <div className="hf-items-cont">
                <div onClick={() => createDiscordInviteLink("footer")}  className="hf-item">
                    <img className="hf-img" src={Discord} alt="Discord Support Icon"/>
                </div>
            </div>
        </div>
    )
}

export default HoverFooter;