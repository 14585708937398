export default function DoYouHaveAccountsWithSpecificStatsOnHypixel()
{
    return (
        <>
            <div className="support-questions-header">Do You Have Accounts With Specific Stats On Hypixel?</div>
            <article className="support-questions-solution">
                <p>We sure do!</p>
                <section>
                    <h3 className="support-answers-section-header">1. StatsFinder</h3>
                    <p>You can check out our stats finder! We list most of the accounts we have containing specific stats for different gamemodes on Hypixel. Check it out!</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">2. Discord Server</h3>
                    <p>Join the discord server as there may be more accounts that you may be interested in. Sometimes we forget to upload the accounts we list on Discord onto the website. You can also enjoy occasional giveaways, coupon codes and updates by joining our server. We hope to see you there!</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}