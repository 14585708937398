import { Link } from "react-router-dom";
import { ShopProducts } from "../../constants/AccountsInfo";
import { convertToFormattedDateTime } from "../../util/helperFunctions";
import { useEffect, useState } from "react";

export default function OrderPreview({order})
{
    const [ orderItems, setOrderItems ] = useState([]);

    useEffect(() => {
        setOrderItems(order.shoppingCart.shoppingCartItems);
    }, [])

    return (
        <div className="m-orders-preview">
            <div className="m-orders-preview-header">
                <div className="m-orders-preview-header-item">
                    <span className="m-orders-preview-header-title">Order Id:</span>
                    <span className="m-orders-preview-header-content">{order.invoiceId}</span>
                </div>
                <div className="m-orders-preview-header-item">
                    <span className="m-orders-preview-header-title">Total:</span>
                    <span className="m-orders-preview-header-content">${(order.invoicePrice / 100).toFixed(2)}</span>
                </div>
                <div className="m-orders-preview-header-item">
                    <span className="m-orders-preview-header-title">Date:</span>
                    <span className="m-orders-preview-header-content">{convertToFormattedDateTime(order.invoiceFulfilled)}</span>
                </div>
                <div className="m-orders-preview-header-item">
                    <span className="m-orders-preview-header-title">Order Status:</span>
                    <span className="m-orders-preview-header-content order-status-complete">Complete</span>
                </div>
            </div>
            <div className="m-orders-info">
                <div className="m-orders-preview-cont">
                    {orderItems.map(orderItem => {
                        return <div className="m-order-preview-info-cont">
                                <div className="m-order-preview-img-cont">
                                    <img className="m-order-preview-img" src={ShopProducts.get(orderItem.product.productNameId).imageUrl} />
                                </div>
                                <div>
                                    <div className="m-order-preview-info">
                                        {orderItem.product.title}
                                    </div>
                                </div>
                            <div className="m-o-p-details">
                                <div className="m-o-p-d-item">
                                    <div className="m-o-p-d-title">
                                        Quantity
                                    </div>
                                    <div className="m-o-p-d-content">
                                        1
                                    </div>
                                </div>
                            </div>
                        </div>})}
                </div>
                <div className="m-order-preview-actions">
                    <Link to={`/order/${order.invoiceId}`} className="m-order-preview-action-item">View Order</Link>
                    <Link to={"/support"} className="m-order-preview-action-item">Contact Support</Link>
                </div>
            </div>
        </div>
    )
}