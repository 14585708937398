export default function AfterIPayHowLongDoINeedToWaitToReceiveMyProduct()
{
    return (
        <>
            <div className="support-questions-header">After I Pay How Long Do I Need To Wait To Receive My Product?</div>
            <article className="support-questions-solution">
                <p>Generally most orders will be sent as soon as the funds are received. However, check out the payment methods below to check exactly when you will receive the product after paying.</p>
                <section>
                    <h3 className="support-answers-section-header">Payment methods</h3>
                    <ul className="support-answers-ul">
                        <li><b>PayPal: </b>Since paying with PayPal requires contacting a member of the team, you will receive the product once the PayPal owner confirms that the funds have been received with correct protocols.</li>
                        <li><b>Crypto: </b>The product will be delivered as soon as the Crypto transaction gets confirmed on the blockchain.</li>
                        <li><b>CashApp: </b>When paying with CashApp, you will be instructions to enter the receipt. As soon as the receipt is entered and the payment is verified by the system. The product will be delivered. This process takes around 5 - 10 seconds.</li>
                        <li><b>Stripe (Card/ApplePay/Google Pay/etc.): </b> Immediately after paying you will be redirected to your order link where you can view your product.</li>
                    </ul>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}