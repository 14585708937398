export const AppConstants = 
{
    // BASE_URL: "http://localhost:8080",
    // WEB_URL: "http://localhost:3000",
    
    DISCORD_REGULAR_INVITE: "https://discord.com/invite/wrsrKw3dTv",

    BASE_URL: "https://altsgreat-72x9.onrender.com",
    WEB_URL: "https://altsgreat.com",
}

export const RouteConstants =
{
    SECURER: "/securer",
    LOGIN_API_URL: "/user",
    REGISTER_URL: "/register",
    AUTHORITIES_URL: "/authorities",
    PRODUCTS_URL: "/products",
    INVOICES_URL: "/invoices",
    USERS_URL: "/users",
    STRIPE_URL: "/stripe",
    SERIAL_URL: "/serials",
    ACCOUNT_URL: "/accounts",
    REFERENCE_URL: "/references",
    SHOPPING_CART_URL: "/shoppingcart",
    VERIFY_URL: "/verify",
    CURRENCY_URL: "/api/currency/rates",
    DISCORD_URL: "/api/discord"
}