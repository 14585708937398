import "./shoppingCart.css"

import shoppingCart from "../../images/trolley.webp";
import error from "../../images/remove.webp"

import { Link } from "react-router-dom";
import { hideElementById } from "../../util/hideShowElementById";

function ShoppingCartAdded()
{
    return (
        <div id="shopping-cart-added-cont" className="shopping-cart-added-cont">
            <div id="shopping-cart-item-added" className="shopping-cart-added-box">
                <div className="s-c-a-b-header">Item Added</div>
                <img src={shoppingCart} className="s-c-a-b-img"/>
                <p className="s-c-a-b-msg">Your cart has successfully been updated</p>
                <div className="s-c-a-b-button-cont">
                    <div className="s-c-a-b-button" onClick={() => hideElementById("shopping-cart-added-cont")}>Continue Shopping</div>
                    <Link to={"/shoppingcart"} className="s-c-a-b-button s-c-a-b-view-cart">View Cart</Link>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartAdded;