import { Link } from "react-router-dom";

export default function PaymentQuestions()
{
    return (
    <div className="support-questions-cont">
        <div className="support-questions-header">Payments</div>
        <div className="support-main-questions-cont">
            <Link to="/support/payments/after-i-pay-how-long-do-i-need-to-wait-to-receive-my-product" className="support-main-questions-item">After I pay, how long do I have to wait to receive my product?</Link>
            <Link to={"/support/payments/i-did-not-receive-an-email-after-paying"} className="support-main-questions-item">I did not receive an email after paying. What now?</Link>
            <Link to={"/support/payments/my-payment-failed-or-got-declined"} className="support-main-questions-item">My transaction failed or got declined. What now?</Link>
            <Link to={"/support/payments/how-can-i-pay-with-amazon-gift-cards"} className="support-main-questions-item">How can I pay with a USA Amazon Gift Card?</Link>
        </div>
    </div>)
}