import { Link } from "react-router-dom";
import { getAllAccounts } from "../../services/account";
import AccountPreview from "./accountPreview";
import "./statfinder.css"

import { useEffect, useState } from "react"
import ActiveFilter from "./activeFilter";
import { hideElementById } from "../../util/hideShowElementById";
import { getAccountProducts } from "../../services/product";

function StatsFinder() {
    const [product, setProduct] = useState({})
    const [allAccounts, setAllAccounts] = useState([])
    const [accounts, setAccounts] = useState([])

    const [appliedFilters, setAppliedFilters] = useState()

    const [selectedGamemode, setSelectedGamemode] = useState()
    const [priceMinFilter, setPriceMinFilter] = useState();
    const [priceMaxFilter, setPriceMaxFilter] = useState();
    const [capeFilter, setCapeFilter] = useState();
    const [rankFilter, setRankFilter] = useState();
    const [bedwarsStarsFilter, setBedwarsStarsFilter] = useState();
    const [bedwarsFkdrFilter, setBedwarsFkdrFilter] = useState();
    const [skywarsPrestigeFilter, setSkywarsPrestigeFilter] = useState();
    const [skywarsKdrFilter, setSkywarsKdrFilter] = useState();
    const [megawallsCoinsFilter, setMegawallsCoinsFilter] = useState();
    const [pitPrestigeFilter, setPitPrestigeFilter] = useState()

    const orderAmount = 1

    const onload = async () => {
        if (accounts.length === 0) {
            const retrievedAccounts = await getAccountProducts();
            if(retrievedAccounts.length == 0)
            {
                document.getElementById("sf-no-matches").style.display = "block"
                hideElementById("sf-accounts")
            }
            setAllAccounts(retrievedAccounts)
            setAccounts(retrievedAccounts)
        }
        document.querySelector("#navbar-cont").style.zIndex = 0;
    }

    useEffect(() => {
        if (allAccounts.length === 0 && !pitPrestigeFilter && !rankFilter && !priceMinFilter && !priceMaxFilter && !rankFilter && !bedwarsStarsFilter && !bedwarsFkdrFilter && !skywarsPrestigeFilter && !skywarsKdrFilter && !megawallsCoinsFilter && !capeFilter) {
            onload()
        }
        else {
            filter()
        }
    }, [capeFilter, pitPrestigeFilter, rankFilter, priceMinFilter, priceMaxFilter, rankFilter, bedwarsStarsFilter, bedwarsFkdrFilter, skywarsPrestigeFilter, skywarsKdrFilter, megawallsCoinsFilter])

    const clearFilters = () => 
    {
        setSelectedGamemode(null);
        setPriceMinFilter(null);
        setPriceMaxFilter(null);
        setRankFilter(null);
        setBedwarsStarsFilter(null);
        setBedwarsFkdrFilter(null);
        setSkywarsPrestigeFilter(null);
        setSkywarsKdrFilter(null);
        setMegawallsCoinsFilter(null);
        setPitPrestigeFilter(null);
        setCapeFilter(null)
    }

    const filterPrice = () => {
        const minValue = document.getElementById('min-price').value;
        const maxValue = document.getElementById('max-price').value;

        if (!minValue && !maxValue) {
            document.getElementById('min-and-max-empty').style.display = "block"
        } else if (maxValue && maxValue != "" && minValue > maxValue) {
            document.getElementById('min-greater-than-max').style.display = "block"
        } else {
            document.getElementById('min-greater-than-max').style.display = "none"
            document.getElementById('min-and-max-empty').style.display = "none"

            setPriceMinFilter(minValue);
            setPriceMaxFilter(maxValue);
        }
    }

    const filterFkdr = () => 
    {
        const fkdr = document.getElementById('filter-fkdr').value;

        if (!fkdr) 
        {
            document.getElementById('bedwars-fkdr-not-number').style.display = "block"
        } else {
            setBedwarsFkdrFilter(fkdr);
        }
    }

    const filterSkywarsKdr = () => 
    {
        const skywarsKdr = document.getElementById('filter-skywars-kdr').value;

        if (!skywarsKdr) {
            document.getElementById('skywars-kdr-not-number').style.display = "block"
        } else {
            setSkywarsKdrFilter(skywarsKdr);
        }
    }

    const filterPitPrestige = () => 
    {
        const pitPrestige = document.getElementById('filter-pit-prestige').value;

        if (!pitPrestige) 
        {
            document.getElementById('pit-prestige-not-number').style.display = "block"
        } else {
            setPitPrestigeFilter(pitPrestige);
        }
    }

    const filterMegawallsCoins = () => 
    {
        const megawallsCoins = document.getElementById('megawalls-filter').value;

        if (!megawallsCoins) {
            document.getElementById('megawalls-coins-not-number').style.display = "block"
        } else {
            setMegawallsCoinsFilter(megawallsCoins);
        }
    }

    const updateRankFilter = (value) => 
    {
        setRankFilter(value);
    };

    const updateCapeFilter = (value) => 
    {
        setCapeFilter(value);
    };

    const updateBedwarsStarsFilter = (value) => 
    {
        setBedwarsStarsFilter(value);
    };

    const updateSkywarsPrestigeFilter = (value) => 
    {
        setSkywarsPrestigeFilter(value);
    };

    const filterGamemode = (gamemodeId, gamemode) => 
    {
        setSelectedGamemode(gamemode)

        const filters = ['bedwars-filter', 'skywars-filter', 'pit-filter', 'megawalls-filter'];
        filters.forEach(filter => {
            if (filter === gamemodeId) 
            {
                document.getElementById(filter).style.display = 'block';
            } else {
                hideElementById(filter)
            }
        });
    }

    const filter = () => {
        let filteredAccounts = [];
        let noResults = true;

        if (rankFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.hypixelRank === rankFilter)
                noResults = false;
            }
            else
                filteredAccounts = filteredAccounts.filter(account => account.hypixelRank === rankFilter)
        }

        if (capeFilter) 
        {
            console.log(capeFilter)
            if (noResults) 
            {
                filteredAccounts = allAccounts.filter(account => account.tags.includes(capeFilter))
                noResults = false;
            }else{
                filteredAccounts = filteredAccounts.filter(account => account.tags.includes(capeFilter))
            }
        }
        
        if (skywarsPrestigeFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.skywarsPrestige === skywarsPrestigeFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.skywarsPrestige === skywarsPrestigeFilter)
        }

        if (skywarsKdrFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.skywarsKdr >= skywarsKdrFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.skywarsKdr >= skywarsKdrFilter)
        }

        if (pitPrestigeFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.pitPrestige >= pitPrestigeFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.pitPrestige >= pitPrestigeFilter)
        }

        if (megawallsCoinsFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.megawallsCoins >= megawallsCoinsFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.megawallsCoins >= megawallsCoinsFilter)
        }

        if (bedwarsStarsFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.bedwarsLevel >= bedwarsStarsFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.bedwarsLevel >= bedwarsStarsFilter)
        }

        if (bedwarsFkdrFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.bedwarsFKDR >= bedwarsFkdrFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.bedwarsFKDR >= bedwarsFkdrFilter)
        }

        if (priceMinFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.price >= priceMinFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.price >= priceMinFilter)
        }

        if (priceMaxFilter) 
        {
            if (noResults) {
                filteredAccounts = allAccounts.filter(account => account.price <= priceMaxFilter)
                noResults = false;
            } else
                filteredAccounts = filteredAccounts.filter(account => account.price <= priceMaxFilter)
        }

        if (filteredAccounts.length === 0) 
        {
            if (!pitPrestigeFilter && !rankFilter && !priceMinFilter && !priceMaxFilter && !rankFilter && !bedwarsStarsFilter && !bedwarsFkdrFilter && !skywarsPrestigeFilter && !skywarsKdrFilter && !megawallsCoinsFilter) 
            {
                setAccounts(allAccounts)
            } else {
                document.getElementById("sf-no-matches").style.display = "block"
                hideElementById("sf-accounts")
            }
        } else {
            document.getElementById("sf-accounts").style.display = "flex"
            hideElementById("sf-no-matches")
            setAccounts(filteredAccounts)
        }
    }

    return (
        <div id="statfinder-cont">
            <div className="statfinder-modal">
                <div className="stats-header-cont">
                    <h3>Hypixel Stats Account Finder</h3>
                    <Link to={"/"} className="stats-finder-buttons">Cancel</Link>
                </div>
                <div className="sf-nav">
                    <div className="sf-nav-gamemodes">
                        <div className={`sf-nav-item ${selectedGamemode === "bedwars" ? "gamemode-selected" : ""}`} onClick={() => filterGamemode('bedwars-filter', 'bedwars')}>Bedwars</div>
                        <div className={`sf-nav-item ${selectedGamemode === "skywars" ? "gamemode-selected" : ""}`} onClick={() => filterGamemode('skywars-filter', 'skywars')}>Skywars</div>
                        <div className={`sf-nav-item ${selectedGamemode === "pit" ? "gamemode-selected" : ""}`} onClick={() => filterGamemode('pit-filter', 'pit')}>Pit</div>
                        <div className={`sf-nav-item ${selectedGamemode === "megawalls" ? "gamemode-selected" : ""}`} onClick={() => filterGamemode('megawalls-filter', 'megawalls')}>Megawalls</div>
                    </div>
                    <div className="sf-nav-results">
                        <div>
                            <b>Results:</b> {accounts.length}
                        </div>
                        <div className="sf-active-filters-actions">
                            <div className="sf-active-filters-action" onClick={clearFilters}>Clear Filters</div>
                        </div>
                    </div>
                </div>
                <div className="sf-active-filters">
                    {/* <div className="sf-active-filters-tags">
                        <div className="sf-active-filters-header">Filters:</div>
                        <ActiveFilter name="100 Stars"/>
                        <ActiveFilter name="Bedwars"/>
                        <ActiveFilter name="3.4 FKDR"/>
                        <ActiveFilter name="Bedwars"/>
                    </div> */}
                </div>
                <div className="sf-main-cont">
                    <div className="sf-main-filter">
                        <div id="hypixel-price-filter">
                            <h4 className="sf-main-filter-title">Price</h4>
                            <div className="sf-main-filter-price-cont">
                                <input id="min-price" className="sf-main-filter-input" placeholder="$ Min-Price" type="number" />
                                <span>-</span>
                                <input id="max-price" className="sf-main-filter-input" placeholder="$ Max-Price" type="number" />
                                <button className="sf-main-filter-btn" onClick={filterPrice}>Search</button>
                            </div>
                            <div className="sf-filter-err-msg" id="min-greater-than-max">Min Price must be under Max Price</div>
                            <div className="sf-filter-err-msg" id="min-and-max-empty">Must enter a number</div>
                        </div>
                        <div id="hypixel-rank-filter">
                            <h4 className="sf-main-filter-title">Ranks</h4>
                            <div className={`sf-main-filter-option ${rankFilter === "MVP+" ? "filter-selected" : ""}`} onClick={() => updateRankFilter("MVP+")}>MVP+</div>
                            <div className={`sf-main-filter-option ${rankFilter === "MVP" ? "filter-selected" : ""}`} onClick={() => updateRankFilter("MVP")}>MVP</div>
                            <div className={`sf-main-filter-option ${rankFilter === "VIP+" ? "filter-selected" : ""}`} onClick={() => updateRankFilter("VIP+")}>VIP+</div>
                            <div className={`sf-main-filter-option ${rankFilter === "VIP" ? "filter-selected" : ""}`} onClick={() => updateRankFilter("VIP")}>VIP</div>
                            <div className={`sf-main-filter-option ${rankFilter === "NONE" ? "filter-selected" : ""}`} onClick={() => updateRankFilter("NONE")}>NONE</div>
                        </div>
                        <div id="cape-filters">
                            <h4 className="sf-main-filter-title">Capes</h4>
                            <div className={`sf-main-filter-option ${capeFilter === "Cherry" ? "filter-selected" : ""}`} onClick={() => updateCapeFilter("Cherry")}>Cherry Blossom</div>
                            <div className={`sf-main-filter-option ${capeFilter === "Vanilla" ? "filter-selected" : ""}`} onClick={() => updateCapeFilter("Vanilla")}>Vanilla</div>
                            <div className={`sf-main-filter-option ${capeFilter === "Migrator" ? "filter-selected" : ""}`} onClick={() => updateCapeFilter("Migrator")}>Migrator</div>
                        </div>
                        <div id="bedwars-filter" className="sf-filter-section">
                            <h4 className="sf-main-filter-title">Bedwars Stars</h4>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 100 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(100)}>100+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 200 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(200)}>200+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 300 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(300)}>300+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 400 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(400)}>400+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 500 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(500)}>500+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 600 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(600)}>600+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 700 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(700)}>700+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 800 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(800)}>800+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 900 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(900)}>900+</div>
                            <div className={`sf-main-filter-option ${bedwarsStarsFilter === 1000 ? "filter-selected" : ""}`} onClick={() => updateBedwarsStarsFilter(1000)}>1,000+</div>
                            <h4 className="sf-main-filter-title">Bedwars FKDR</h4>
                            <div className="sf-fkdr-cont">
                                <input id="filter-fkdr" className="sf-main-filter-input" placeholder="Min. FKDR" type="number" />
                                <button className="sf-main-filter-btn" onClick={filterFkdr}>Search</button>
                            </div>
                            <div className="sf-filter-err-msg" id="bedwars-fkdr-not-number">Must enter a number</div>
                        </div>
                        <div id="skywars-filter" className="sf-filter-section">
                            <h4 className="sf-main-filter-title">Skywars Prestige</h4>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "None" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("None")}>None</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Iron" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Iron")}>Iron</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Gold" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Gold")}>Gold</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Diamond" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Diamond")}>Diamond</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Emerald" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Emerald")}>Emerald</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Sapphire" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Sapphire")}>Sapphire</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Ruby" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Ruby")}>Ruby</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Crystal" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Crystal")}>Crystal</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Opal" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Opal")}>Opal</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Amethyst" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Amethyst")}>Amethyst</div>
                            <div className={`sf-main-filter-option ${skywarsPrestigeFilter === "Rainbow" ? "filter-selected" : ""}`} onClick={() => updateSkywarsPrestigeFilter("Rainbow")}>Rainbow</div>
                            <h4 className="sf-main-filter-title">Skywars KDR</h4>
                            <div className="sf-fkdr-cont">
                                <input id="filter-skywars-kdr" className="sf-main-filter-input sf-long-input" placeholder="Min. KDR" type="number" />
                                <button className="sf-main-filter-btn" onClick={filterSkywarsKdr}>Search</button>
                            </div>
                            <div className="sf-filter-err-msg" id="skywars-kdr-not-number">Must enter a number</div>
                        </div>
                        <div id="pit-filter" className="sf-filter-section">
                            <h4 className="sf-main-filter-title">Pit Prestige</h4>
                            <div className="sf-fkdr-cont">
                                <input id="filter-pit-prestige" className="sf-main-filter-input sf-long-input" placeholder="Min. Pit Prestige" type="number" />
                                <button className="sf-main-filter-btn" onClick={filterPitPrestige}>Search</button>
                            </div>
                            <div className="sf-filter-err-msg" id="pit-prestige-not-number">Must enter a number</div>
                        </div>
                        <div id="megawalls-filter" className="sf-filter-section">
                            <h4 className="sf-main-filter-title">Megawalls Coins</h4>
                            <div className="sf-fkdr-cont">
                                <input id="filter-megawalls-coins" className="sf-main-filter-input sf-long-input" placeholder="Min. Megawalls Coins" type="number" />
                                <button className="sf-main-filter-btn" onClick={filterMegawallsCoins}>Search</button>
                            </div>
                            <div className="sf-filter-err-msg" id="megawalls-coins-not-number">Must enter a number</div>
                        </div>
                    </div>
                    <div id="sf-no-matches">
                        No Results Found
                        <p>Please try another search</p>
                    </div>
                    <div id="sf-accounts" className="sf-accounts-preview-cont">
                        {accounts.map((product) => {
                            return <AccountPreview product={product} setProduct={setProduct}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatsFinder