export default function HowCanIPayWithAmazonGiftCard()
{
    return (
        <>
            <div className="support-questions-header">How can I pay with a USA Amazon Gift Card</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">USA Amazon Gift Card</h3>
                    <ol>
                        <li>Please ensure that the card is a USA Amazon Gift card as we do not accept any other card.</li>
                        <li>Join our discord server and create an order choosing "USA Amazon Gift Card" as the payment method.</li>
                        <li>Send the gift card code and wait for a staff member to review the code and mark the order as paid.</li>
                        <li>Check your email for the product details.</li>
                    </ol>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}