import { useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import "./css/support.css";
import "./css/answers.css";

import aboutus from "../../images/aboutus.webp"
import popularquestions from "../../images/popularquestions.webp"
import payments from "../../images/payments.webp"
import warranty from "../../images/warranty.webp"
import products from "../../images/products.webp"

import Navbar from "../navbar/navbar";

import { hideElementById } from "../../util/hideShowElementById";
import { addAlert } from "../../util/showNotif";

import PopularQuestions from "./questions/popular";
import PaymentQuestions from "./questions/payments";
import ProductQuestions from "./questions/products";
import AboutUsQuestions from "./questions/aboutus";
import WarrantyQuestions from "./questions/warranty";

import DigitalItemThatIPurchasedDoesntWorkOrStoppedWorkingWhatDoIDo from "./answers/DigitalItemThatIPurchasedDoesntWorkOrStoppedWorkingWhatDoIDo";
import AfterIPayHowLongDoINeedToWaitToReceiveMyProduct from "./answers/AfterIPayHowLongDoIHaveToWaitToReceiveMyProduct";
import IsAltsGreatLegitAndSafeToBuyFrom from "./answers/IsAltsGreatLegitAndSafeToBuyFrom";
import HowLongDoesTheWarrantyLastAfterPurchasing from "./answers/HowLongDoesTheWarrantyLastAfterPurchasing";
import WhatIsTheRefundPolicy from "./answers/WhatIsTheRefundPolicy";
import HowDoesThisWork from "./answers/HowDoesThisWork";
import IPaidHowDoIClaimIt from "./answers/IPaidForMyProductHowDoIClaimIt";
import IDidNotReceiveAnEmailAfterPayingWhatNow from "./answers/IDidNotReceiveAnEmailAfterPayingWhatNow";
import MyPaymentFailedOrGotDeclined from "./answers/MyPaymentFailedOrGotDeclined";
import HowCanIPayWithAmazonGiftCard from "./answers/HowCanIPayWithAmazonGiftCard";
import WhatDoesFullAccessMean from "./answers/WhatDoesFullAccessMean";
import DifficultyDecidingBetweenProducts from "./answers/DifficultyDecidingBetweenProducts";
import DoYouHaveAccountsWithSpecificStatsOnHypixel from "./answers/DoYouHaveAccountsWithSpecificStatsOnHypixel";
import SupportBreadCrumb from "./supportBreadcrumb";
import { createDiscordInviteLink } from "../../services/discord";
import WhoAreWe from "./answers/WhoAreWe";

export default function Support()
{
    const location = useLocation();

    useEffect(() => {
        console.log(location)
        hideElementById('pn-nav-bar');
        hideElementById('n-b-top');
    }, [])

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        addAlert(`Copied ${e.target.innerText} to clipboard`, "success", 3000)
    }

    const redirectDiscord = (e) => {
        addAlert(`Redirecting to the Discord Server...`, "success", 3000)
        createDiscordInviteLink("support")
    }

    return (
    <>
        <Navbar/>
        <div className="support-page-cont">
            <div className="support-navbar">
                <div className="support-navbar-inner">
                    <Link to={"/support"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={popularquestions}/>
                        <div className="support-navbar-item-text">Popular Questions</div>
                    </Link>
                    <Link to={"/support/payments"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={payments}/>
                        <div className="support-navbar-item-text">Payments</div>
                    </Link>
                    <Link to={"/support/products"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={products}/>
                        <div className="support-navbar-item-text">Products</div>
                    </Link>
                    <Link to={"/support/aboutus"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={aboutus}/>
                        <div className="support-navbar-item-text">About Us</div>
                    </Link>
                    <Link to={"/support/warranties"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={warranty}/>
                        <div className="support-navbar-item-text">Warranty</div>
                    </Link>
                </div>
            </div>
            <div className="support-main">
                <div className="support-answer-page">
                    <SupportBreadCrumb/>
                    <Routes>
                        <Route path="/" element={<PopularQuestions />}/>
                        <Route path="/payments" element={<PaymentQuestions />}/>
                        <Route path="/payments/i-paid-for-my-product-how-do-i-claim-it" element={<IPaidHowDoIClaimIt/>} />
                        <Route path="/payments/i-did-not-receive-an-email-after-paying" element={<IDidNotReceiveAnEmailAfterPayingWhatNow/>} />
                        <Route path="/payments/my-payment-failed-or-got-declined" element={<MyPaymentFailedOrGotDeclined />} />
                        <Route path="/payments/how-can-i-pay-with-amazon-gift-cards" element={<HowCanIPayWithAmazonGiftCard />} />
                        <Route path="/payments/after-i-pay-how-long-do-i-need-to-wait-to-receive-my-product" element={<AfterIPayHowLongDoINeedToWaitToReceiveMyProduct/>} />

                        <Route path="/products" element={<ProductQuestions />}/>
                        <Route path="/products/what-does-full-access-mean" element={<WhatDoesFullAccessMean />} />
                        <Route path="/products/i-am-having-difficulties-deciding-which-product-i-should-purchase" element={<DifficultyDecidingBetweenProducts />} />
                        <Route path="/products/do-you-have-accounts-with-specific-stats-on-hypixel" element={<DoYouHaveAccountsWithSpecificStatsOnHypixel />} />

                        <Route path="/aboutus"  element={<AboutUsQuestions />}/>
                        <Route path="/aboutus/how-does-this-work" element={<HowDoesThisWork/>} />
                        <Route path="/aboutus/is-alts-great-legit-and-safe-to-buy-from" element={<IsAltsGreatLegitAndSafeToBuyFrom/>} />
                        <Route path="/aboutus/who-are-we" element={<WhoAreWe/>} />

                        <Route path="/warranties" element={<WarrantyQuestions/>}/>
                        <Route path="/warranties/how-long-does-the-warranty-last-after-purchasing" element={<HowLongDoesTheWarrantyLastAfterPurchasing/>} />
                        <Route path="/warranties/what-is-the-refund-policy" element={<WhatIsTheRefundPolicy/>} />

                        <Route path="/popular-questions" element={<PopularQuestions />}/>
                        <Route path="/popular-questions/digital-item-that-i-purchased-doesnt-work-or-stopped-working-what-do-i-do" element={<DigitalItemThatIPurchasedDoesntWorkOrStoppedWorkingWhatDoIDo />}/>
                    </Routes>
                </div>
                <div className="support-contact-cont">
                    <div className="support-contact-info-header">Get in touch with support</div>
                    <div className="support-contact-item">
                        <div className="support-contact-item-header">Contact via Discord.</div>
                        <div className="support-contact-discord" onClick={redirectDiscord}>Join the Discord Server</div>
                    </div>
                    <div className="support-contact-item">
                        <div className="support-contact-item-header">Email us</div>
                        <div className="support-contact-email" onClick={copyToClipboard}>support@altsgreat.com</div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};