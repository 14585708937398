import { ShopProducts } from "../../constants/AccountsInfo"
import { hideElementById, showElementById } from "../../util/hideShowElementById"

import FullAccessSerial from "./fullAccessSerial";

export default function OrderItem({shoppingCartItem, setSerials, setActiveSerial})
{
    const openOrderDetails = () => 
    {
        const product = shoppingCartItem.product;
        const serials = shoppingCartItem.serials;

        hideElementById("items-preview-cont");
        showElementById("serial-cont");

        let tempSerials;

        if(product.productNameId === "nfa-unbanned-cookies" ||
            product.productNameId === "skyblock-coins")
        {
            tempSerials = "This product needs to be manually delivered. Join the discord and create a ticket for \"Issue with Order\" to claim."
        }else if(product.productNameId.includes("boost")){
            showElementById("boost-container")
            document.getElementById("boost-container").innerHTML = `<b>1.)</b> Add this bot to the server you want to boost<br>
                        https://discord.com/oauth2/authorize?client_id=1209654956240535622&permissions=1&scope=bot<br>
                        <b>2.)</b> Join our customer discord server using the "Discord" button below</br>
                        <b>3.)</b> In our discord server, run the command /boost {server id} {invoice id}<br>
                        <i>To get server id: Go to "User Settings" on discord -> "Advanced" -> Turn on "Developer Mode" -> Right click your server icon -> Click "Copy Server ID"</i>`
        } else if (serials.length < 1) {
            tempSerials = "Seems like there are no more accounts in stock. This can happen if two people purchased the last account in stock at the same time. Please create a ticket for \"Issue With Order\" in the discord server."
        }else{
            if (product.productNameId.includes("-fa")) {
    
                tempSerials = serials.map(serial => {
                    return <FullAccessSerial serial={serial} setActiveSerial={setActiveSerial} />
                })

                if(product.productNameId.includes("-fa"))
                {
                    showElementById("full-access-serial-msg")
                }
            } else {
                let counter = 1;
                tempSerials = serials.map(serial => {
                    return <div><b>{counter++}:</b> {serial.serialId}</div>
                })
            }
        }
        setSerials(tempSerials);
    }

    return (
        <div className="m-orders-preview">
            <div className="m-orders-info">
                <div className="m-order-preview-info-cont">
                    <div className="m-order-preview-img-cont">
                        <img className="m-order-preview-img" src={ShopProducts.get(shoppingCartItem.product.productNameId).imageUrl} alt="product thumbnail"/>
                    </div>
                    <div>
                        <div className="m-order-preview-info">
                            {shoppingCartItem.product.title}
                        </div>
                    </div>
                </div>
                <div className="m-order-preview-actions">
                    <div className="m-order-preview-action-item" onClick={openOrderDetails}>View Order</div>
                </div>
            </div>
        </div>
    )
}