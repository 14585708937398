import { Link } from "react-router-dom";

export default function WarrantyQuestions()
{
    return (
    <div className="support-questions-cont">
        <div className="support-questions-header">Warranties</div>
        <div className="support-main-questions-cont">
            <Link to={"/support/popular-questions/digital-item-that-i-purchased-doesnt-work-or-stopped-working-what-do-i-do"} className="support-main-questions-item">The digital item that I purchased doesn't work or stopped working. What should I do?</Link>
            <Link to={"how-long-does-the-warranty-last-after-purchasing"} className="support-main-questions-item">How long does my warranty last after purchasing?</Link>
            <Link to="what-is-the-refund-policy" className="support-main-questions-item">What is the return policy?</Link>
        </div>
    </div>)
}