import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/user'

import { addAlert } from '../../util/showNotif';

import "./admin.css"
import { setCookie } from '../../util/cookies';

function AdminLogin()
{
    let navigate = useNavigate(); // Setup to navigate to different routes

    const [formData, setFormData] = useState({
        "email": "",
        "password": "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(formData.email === "" || formData.password === "")
        {
            return addAlert("Missing Fields", "error", 3000)
        }else{
            try{
                let response = await loginUser(formData)
    
                // Checking if login was successful and storing token
                let token = response.headers.authorization;
                if(!token)
                    return addAlert("Invalid Credentials", "error", 3000)
                
                const tokenParts = token.split(".");

                if(!JSON.parse(atob(tokenParts[1])).authorities.includes("ROLE_ADMIN"))
                    return addAlert("Invalid Credentials", "error", 3000)

                setCookie("UTOK", token, 30)
                navigate("/dashboard")
            }catch(err){
                console.log(err)
                if(err.response.status === 401)
                {
                    addAlert("Invalid Credentials", "error", 3000)
                }
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className='rg-cont'>
            <form onSubmit={handleSubmit} className="rg-form l-r">
                <h2 className='rg-input-header'>Email</h2>
                <input 
                    className='rg-input'
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}/>
                <h2 className='rg-input-header'>Password</h2>
                <input
                    className='rg-input'
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}/>
                <input
                    className='rg-submit'
                    type="submit"
                    value="Login"/>
            </form>
        </div>
    );
}

export default AdminLogin;