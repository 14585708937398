import { useEffect } from "react";
import "./accountPreview.css"
import { addItemToShoppingCart } from "../../services/shoppingCart";
import { showElementById } from "../../util/hideShowElementById";
import { addAlert } from "../../util/showNotif";
import { useShoppingCart } from "../../context/shoppingCartContext";

function AccountPreview({product}) 
{
    const { setShoppingCartItemCount } = useShoppingCart();

    const account = product.account;

    const addToCart = async () => 
    {
        const shoppingCartItemInfo = {
            productNameId: product.productNameId,
            quantity: 1
        }

        const response = await addItemToShoppingCart(shoppingCartItemInfo);

        if(response.status === 201)
        {
            showElementById("shopping-cart-added-cont");
            showElementById("shopping-cart-item-added", "flex");

            setShoppingCartItemCount(response.data.itemsCount)
        }else{
            return addAlert("Could not add product to shopping cart", "error", 4000)
        }
    }
    
    return (
        <div className="sf-a-preview-item" onClick={addToCart}>
            <div className="sf-a-p-i-header">
                <h4 className="sf-a-p-i-ign">{account.username} [<span className={`${account.hypixelRank.toLowerCase().split("+")[0]}`}>{account.hypixelRank}</span>]</h4>
                <img className="account-avatar" src={`https://crafatar.com/avatars/${account.uuid}?overlay`} />
            </div>
            <div className="sf-a-p-i-overview">
                <div className="sf-a-p-i-overview-details">
                    <h4 className="sf-a-p-i-overview-h"><u>Hypixel:</u></h4>
                    <p className="sf-a-p-i-overview-p"><b>Level {account.hypixelLevel}</b></p>
                </div>
                <div className="sf-a-p-i-overview-details">
                    <h4 className="sf-a-p-i-overview-h">Bedwars:</h4>
                    <p className="sf-a-p-i-overview-p"><b>{account.bedwarsLevel}</b> ⭐| <b>{account.bedwarsFKDR}</b> FKDR</p>
                </div>
                <div className="sf-a-p-i-overview-details">
                    <h4 className="sf-a-p-i-overview-h">Megawalls:</h4>
                    <p className="sf-a-p-i-overview-p"><b>{account.megawallsCoins}</b> Coins</p>
                </div>
                <div className="sf-a-p-i-overview-details">
                    <h4 className="sf-a-p-i-overview-h">Skywars:</h4>
                    <p className="sf-a-p-i-overview-p"><b>{account.skywarsPrestige}</b> Prestige | <b>{account.skywarsKdr}</b> KDR</p>
                </div>
                <div className="sf-a-p-i-overview-details">
                    <h4 className="sf-a-p-i-overview-h">Pit:</h4>
                    <p className="sf-a-p-i-overview-p"><b>{account.pitPrestige}</b> Prestige</p>
                </div>
                <h4 className="sf-a-p-i-overview-h">Extras:</h4>
                <ul className="sf-a-p-i-overview-ul">
                    {account.tags.split(",").map(tag => {
                        return <li>{tag}</li>
                    })}
                </ul>
            </div>
            <div className="sf-a-p-i-footer">
                <button className="sf-a-p-i-f-button">Add To Cart - ${account.price.toFixed(2)}</button>
            </div>
        </div>
    )
}

export default AccountPreview