export const addAlert = (content, popupType, duration) => {
    const container = document.getElementById('alerts-contatiner');
    const popupCont = document.createElement("div")
    popupCont.className = "notif-popup"
    container.classList.add("slide-up")

    switch(popupType)
    {
        case "error":
            popupCont.classList.add("error-popup")
            content = `${content}`
            break;
        case "warning":
            popupCont.classList.add("warning-popup")
            content = `${content}`
            break;
        case "success":
            popupCont.classList.add("success-popup")
            content = `${content}`
            break;
    }

    popupCont.innerHTML = `<div>
        ${content}
    </div>`

    container.appendChild(popupCont)

    setTimeout(function() {
        container.removeChild(popupCont);
    }, duration);
}