export default function WhoAreWe()
{
    return (
        <>
            <div className="support-questions-header">Who are we?</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Who are we?</h3>
                    <p>To answer that question, we must first introduce who we are. AltsGreat was founded in 2022 with one sole mission: to enhance gamers' experiences all around the world. With that mission, we have put our blood, sweat, and tears into ensuring customers receive the product and are satisfied.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">What do we sell?</h3>
                    <p>We are a marketplace and sell a variety of products. We mainly sell gaming keys and offer licenses to our software which is used in many games such as Minecraft, Valorant, Discord, etc.</p>
                    <p>These keys are sourced from a variety of sources, starting from the game developer themselves to the game users. Game developers wholesale their games to us to reach out to a wider customer base. Game developers also give out free copies of their game from time to time and users who claim their free copy come and sell their copy through us.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Are we legit and safe to buy from?</h3>
                    <p>All of our products are verified and checked before they are uploaded. So are we legit? Absolutely! We take pride in being a trusted and reliable shop. Our business is fully registered and compliant with all relevant laws and regulations. We have countless satisfied customers who can vouch for our authenticity. Additionally, we use secure payment methods to protect your transactions, and our customer service team is always here to help with any concerns. You can shop with confidence knowing you're dealing with a legitimate and reputable business.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Why should you choose us?</h3>
                    <p>Unlike other shops that require you to go through a long process of claiming your account and switching it to your own email. We provide a solution that will allow you do to so in under a minute. We have the highest rated customer service in the industry and trained staff members who will treat ensure you feel thanked for choosing us.</p>
                    <p>Not convinced? We provide cashback for every single purchase if you are registered. We deliver products instantly for the most popular payment methods and our website is programmed by the most experienced developers that allow you to have a quick and easy experience while browsing through our website.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}