import { Link } from "react-router-dom";
import { getCookie } from "../../util/cookies";
import { useShoppingCart } from "../../context/shoppingCartContext";
import { logout } from "../../util/logout";

import shoppingCartIcon from '../../images/shoppingcart.webp'
import { useEffect, useRef, useState } from "react";

export default function NavbarDropDown() 
{
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { shoppingCartItemCount } = useShoppingCart();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false); // Close the dropdown if clicked outside
          }
        };
    
        // Add event listener
        document.addEventListener('click', handleClickOutside);
    
        // Cleanup event listener on unmount
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="mc-nav-bar-small-main" onClick={toggleMenu} ref={dropdownRef}>
            <Link to={"/shoppingcart"} className="shopping-cart-icon-cont">
                <img className="shopping-cart-icon" src={shoppingCartIcon} alt="shopping cart icon" />
                <strong className="shopping-cart-number">{shoppingCartItemCount}</strong>
            </Link>
            <div className="m-b-s-m-icon" id="m-b-s-m-icon">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            {isOpen && (
                <div className="m-b-s-m-items" id="m-b-s-m-items">
                    {/** If the user if logged in then show the dashboard and logout button */}
                    {getCookie("UTOK") ?
                        <>
                            <Link to={"/users"} className="m-b-s-m-action" id="nav-bar-dashboard">
                                Dashboard
                            </Link>
                            <div className="m-b-s-m-action" onClick={logout}>
                                Logout
                            </div>
                        </> :
                        <Link to={"/login"} className="m-b-s-m-action">Login</Link>}
                </div>
            )}
        </div>
    )
}