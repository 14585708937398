import "./slideshow.css"

function SlideShow({id, href, img, header, msg})
{
    return (
        <a id={id} href={href} className="slideshows info-slide" target="_blank" rel="noreferrer">
            <div className="slideshow-contents">
                <img className="slideshows-image" src={img} alt="Minecraft Alts Slideshow"/>
                <div className="slideshows-words">
                    <h3 className="slideshow-header">
                        {header}
                    </h3>
                    <p>
                        {msg}
                    </p>
                </div>
            </div>
        </a>
    )
}

export default SlideShow