import { Link } from "react-router-dom";
import { ShopInfo } from "../../../constants/ShopInfo";

export default function PopularQuestions()
{
    return (
        <div className="support-questions-cont">
            <div className="support-questions-header">Popular Questions</div>
            <div className="support-main-questions-cont">
                <Link to={"/support/popular-questions/digital-item-that-i-purchased-doesnt-work-or-stopped-working-what-do-i-do"} className="support-main-questions-item">The digital item that I purchased doesn't work or stopped working. What should I do?</Link>
                <Link to={"/support/payments/after-i-pay-how-long-do-i-need-to-wait-to-receive-my-product"} className="support-main-questions-item">After I pay, how long do I have to wait to receive my product?</Link>
                <Link to={"/support/aboutus/is-alts-great-legit-and-safe-to-buy-from"} className="support-main-questions-item">Is {ShopInfo.CompanyName} legit and safe to buy from?</Link>
                <Link to={"/support/products/do-you-have-accounts-with-specific-stats-on-hypixel"} className="support-main-questions-item">Do you have accounts with specific stats on Hypixel? Ex. Skyblock, Bedwars, Skywars, etc.</Link>
                <Link to={"/support/aboutus/how-does-this-work"} className="support-main-questions-item">How does this work?</Link>
                <Link to={"/support/payments/i-paid-for-my-product-how-do-i-claim-it"} className="support-main-questions-item">I paid for my product. How do I claim it?</Link>
                <Link to={"/support/payments/i-did-not-receive-an-email-after-paying"} className="support-main-questions-item">I did not receive an email after paying. What now?</Link>
                <Link to={"/support/payments/my-payment-failed-or-got-declined"} className="support-main-questions-item">My payment/transaction failed or got declined. What now?</Link>
            </div>
        </div>)
}