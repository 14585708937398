import { Link, useLocation } from "react-router-dom";

export default function SupportBreadCrumb() {
    const location = useLocation(); // Get the current location
    const pathnames = location.pathname.split('/').filter((x) => x); // Split the pathname and filter out empty segments

    if (pathnames.length === 1 && pathnames[0] === 'support') {
        // return null; // Return null to not render anything
    }

    const capitalize = (str) => {
        return str
            .split('-') // Assuming words are separated by hyphens
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words back with spaces
    };

    return (
        <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
                {/* Home link */}

                {/* Map over the pathnames */}
                {pathnames.map((value, index) => {
                    // Build the path for the current breadcrumb item
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    // If it's the last item, don't make it a link
                    const isLast = index === pathnames.length - 1;
                    return (
                        <li key={to} className={`breadcrumb-item ${isLast ? 'active' : ''}`}>
                            {isLast ? (
                                <span>{capitalize(decodeURIComponent(value.replace(/-/g, ' ')))}</span>
                            ) : (
                                <Link to={to}>{capitalize(decodeURIComponent(value.replace(/-/g, ' ')))}</Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    )
}