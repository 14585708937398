import { RouteConstants } from "../constants/AppConstants";
import api from "./apiConfig";

export const getCurrencyRate = async (currency) =>
{
    // Make request to get payment method info
    try{
        const response = await api.get(`${RouteConstants.CURRENCY_URL}/${currency}`)
        return response.data;
    }catch(error){
        console.error(error)
        return error.response;
    }
}