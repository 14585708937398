export const convertToFormattedDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }).replace('at', '');

    return formattedDate;
}