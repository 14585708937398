import SlideShow from "./slideshow";

import transdiscord from '../../images/discord.webp'
import alexandsteve from '../../images/alexsteve.webp'
import trustpilot from '../../images/trustpilot.webp'
import logo from '../../images/logo.webp'

import { AppConstants } from "../../constants/AppConstants.js";
import { LINKS } from "../../constants/Links.js";
import { createDiscordInviteLink } from "../../services/discord.js";

function SlideShowCont()
{
    return (
        <div className="welcome-cont">
            <div id="slideshow-cont">
                <div className="slideshow-color">
                    {/* <Link to={"/statsfinder"} id="slideshow-one" href="#stats-finder" className="slideshows stats-slide">
                        <div className="slideshow-contents">
                            <img className="slideshows-image" src={logo} alt="Minecraft Alts Slideshow"/>
                            <div className="slideshows-words">
                                <h3 className="slideshow-header">
                                    Hypixel Stat Account Finder
                                </h3>
                                <p className="stats-finder-desc">
                                    Need an account in a specific gamemode on Hypixel? Introducing... stats finder! Search for profiles with stats on your desired gamemode on Hypixel. Including Bedwars, Skywars, Megawalls, Arcade, Duels and Pit with Skyblock coming very soon. Know exactly what you're paying for. Try it out!
                                </p>
                                <button className="search-button">
                                    Search <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </Link> */}
                    <SlideShow
                    id="slideshow-one"
                    href={AppConstants.WEB_URL}
                    img={alexandsteve}
                    header="Welcome to GreatAlts"
                    msg="We offer lifetime warranties, the cheapest prices and the highest quality keys and alts. Shop with us today!" />
                    <SlideShow
                    id="slideshow-two"
                    href={LINKS.TRUSTPILOT}
                    img={trustpilot}
                    header="Satisfaction Guaranteed"
                    msg="We have served MILLIONs of customers and have a 5 star rating on trustpilot, through this we can guarantee satisfaction when shopping with us." />
                    <div id={"slideshow-three"} onClick={() => createDiscordInviteLink("slideshow")} className="slideshows info-slide">
                        <div className="slideshow-contents">
                            <img className="slideshows-image" src={transdiscord} alt="Minecraft Alts Slideshow"/>
                            <div className="slideshows-words">
                                <h3 className="slideshow-header">
                                Join our Discord
                                </h3>
                                <p>
                                Our discord server provides support for customers and anyone who has any questions about our products or services. We provide occasional updates, discounts and giveaways! Join now for free!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideShowCont;