import { Link } from "react-router-dom"

export default function ProductQuestions()
{
    return (
    <div className="support-questions-cont">
        <div className="support-questions-header">Products</div>
        <div className="support-main-questions-cont">
            <Link to={"what-does-full-access-mean"} className="support-main-questions-item">What does "Full Access" mean?</Link>
            <Link to={"i-am-having-difficulties-deciding-which-product-i-should-purchase"} className="support-main-questions-item">I am having difficulties deciding which product I should purchase.</Link>
            <Link to={"i-am-having-difficulties-deciding-which-product-i-should-purchase"} className="support-main-questions-item">What is the difference between Fresh Full Access and Unbanned Full Access?</Link>
            <Link to={"do-you-have-accounts-with-specific-stats-on-hypixel"} className="support-main-questions-item">Do you have accounts with specific stats on Hypixel? Ex. Skyblock, Bedwars, Skywars, etc.</Link>
            <Link to={"/support/aboutus/how-does-this-work"} className="support-main-questions-item">How are these products obtained?</Link>
        </div>
    </div>)
}