import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar/navbar";

import "./css/verify.css"

import error from "../../images/remove.webp"
import check from "../../images/check.webp"

import { useEffect, useRef } from "react";
import { hideElementById, showElementById } from "../../util/hideShowElementById";
import { verifyEmail } from "../../services/user";
import { setCookie } from "../../util/cookies";
import { delay } from "../../util/delay";

function VerifyEmailPage()
{
    // Create a URLSearchParams object
    const params = new URLSearchParams(useLocation().search);

    // Get a specific query parameter
    const email = params.get('email');
    const token = params.get('token');

    const navigate = useNavigate()

    const hasVerified = useRef(false);

    useEffect(() => {
        hideElementById('pn-nav-bar');
        hideElementById('n-b-top');

        const verifyToken = async () =>
        {
            if (hasVerified.current) return; // Prevent running twice
            hasVerified.current = true;

            const verifyEmailStatus = await verifyEmail(email, token);

            hideElementById("email-verification-loading")
            if(verifyEmailStatus.status === 201)
            {
                setCookie("UTOK", verifyEmailStatus.headers.authorization)
                showElementById("email-verification-success");
                await delay(3000)
                return navigate("/users")
            }else
                return showElementById("email-verification-unsuccessful");
        }

        if(token)
        {
            verifyToken();
        }
    }, [token])

    return (
        <div>
            <Navbar />
            <div id="email-verification-loading" className="verify-cont">
                <div className="verify-header-cont">
                    <h3 className="verify-header">Verifiying...</h3>
                    <p className="verify-message">Give us a moment while we verify</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to={"/support"} className="verify-contact">Contact Support</Link>
                </div>
            </div>
            <div id="email-verification-success" className="verify-cont">
                <div className="verify-header-cont">
                    <img src={check} className="verify-img" alt="mail picture"/>
                    <h3 className="verify-header">Verification Successful</h3>
                    <p className="verify-message">Re-directing to customer dashboard in 3 seconds</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to={"/support"} className="verify-contact">Contact Support</Link>
                </div>
            </div>
            <div id="email-verification-unsuccessful" className="verify-cont">
                <div className="verify-header-cont">
                    <img src={error} className="verify-img" alt="mail picture"/>
                    <h3 className="verify-header">Verification Unsuccessful</h3>
                    <p className="verify-message">Check your email and click on the verification link.</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to={"/support"} className="verify-contact">Contact Support</Link>
                </div>
            </div>
        </div>)
}

export default VerifyEmailPage;