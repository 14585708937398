import "./footer.css"

import logo from '../../images/logo.webp'
import { Link } from "react-router-dom";
import { LINKS } from "../../constants/Links";
import { ShopInfo } from "../../constants/ShopInfo";

function Footer()
{
    return (
        <div className="footer-box">
            <div className="footer-cont">
                <div className="footer-items">
                    <div className="footer-store-info">
                        <div className="footer-header">
                            <img className="footer-logo" src={logo} alt="AltsGreat Logo"/>
                        </div>
                        <div className="footer-msg">
                            <h3 className="footer-msg-header">Fueled by {ShopInfo.CompanyName}</h3>
                            <p className="footer-msg-p">{ShopInfo.CompanyName} is the leader in Minecraft Alt technology, offering top-quality accounts known for their reliability and excellence.</p>
                        </div>
                    </div>
                    <ul className="footer-sitemap">
                        <li className="footer-about-us">
                            <h3 className="footer-msg-header">About Us</h3>
                            <Link className="footer-link" to={"/support/aboutus/is-alts-great-legit-and-safe-to-buy-from"}>Why Us?</Link>
                            <Link className="footer-link" to={"/support"}>Support</Link>
                            <Link className="footer-link" to={"/support"}>Frequent Questions</Link>
                            <a className="footer-link" href={LINKS.TRUSTPILOT} target="_blank" rel="noreferrer">Reviews</a>
                        </li>
                        <li className="footer-legal">
                            <h3 className="footer-msg-header">Legal</h3>
                            <Link className="footer-link" to={"/help/terms-of-service"}>Terms of Service</Link>
                            <Link className="footer-link" to={"/help/privacy"}>Privacy Policy</Link>
                            <Link className="footer-link" to={"/help/return-policy"}>Return Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-company-details">
                <p>© 2024 Altsgreat.com. All Rights Reserved</p>
                <p>Registered as KeyCommerce LLC | 631-419-3568 | support@altsgreat.com</p>
            </div>
        </div>
    );
}

export default Footer