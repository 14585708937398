import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { addAlert } from "../../util/showNotif";

import ProductSection from "./ProductSection";

import "./product.css"
import ProductDetails from './productDetails';
import { showElementById } from '../../util/hideShowElementById';
import { addItemToShoppingCart } from '../../services/shoppingCart';
import ShoppingCartAdded from '../shoppingcart/shoppingCartAdded';
import { useCurrency } from '../../context/currencyContext';
import { useShoppingCart } from '../../context/shoppingCartContext';
import { getProductByNameId } from '../../services/product';
import { ShopProducts } from '../../constants/AccountsInfo';

function ProductPage()
{
    const productNameId = window.location.pathname.split("/")[2]

    const { currency } = useCurrency()

    const { setShoppingCartItemCount } = useShoppingCart();

    const [ product, setProduct ] = useState({})
    const [ orderAmount, setOrderAmount ] = useState(1)
    const [ instructions, setInstructions ] = useState("")
    const [ features, setFeatures ] = useState([])

    const onLoad = async () => 
    {
        let response;
        try{
            response = await getProductByNameId(productNameId)
        }catch(err){
            console.log(err)
        }

        if(response.data)
        {
            const retrievedProduct = response.data;
            ShopProducts.get(productNameId).quantity = retrievedProduct.quantity;
            setProduct(ShopProducts.get(productNameId));
            setOrderAmount(retrievedProduct.minimum)

            if (productNameId === "fresh-fa") 
            {
                setInstructions("Upon purchase, you will be instructed to provide an email which will be used to log into the Minecraft account. You will also receive a complete step by step guide on how to securely transfer the email to your personal email.")
                setFeatures(['Java + Bedrock Version', 'Permanent Access', '100% Customizable', 'Instant Email Delivery', '24/7 Customer Service', 'Lifetime Warranty'])
            } else if (productNameId.includes("-fa")) {
                setInstructions("Upon purchase, you will be instructed to provide an email which will be used to log into the Minecraft account. You will also receive a complete step by step guide on how to securely transfer the email to your personal email.")
                setFeatures(['Java + Bedrock Version', 'Permanent Access', '100% Customizable', 'Instant Email Delivery', '24/7 Customer Service', 'Lifetime Warranty'])
                const faFeatures = document.getElementsByClassName("fa-features")
                for (let i = 0; i < faFeatures.length; i++)
                    faFeatures[i].style.display = "block"
            } else if (productNameId.includes("nfa")) {
                setInstructions("After purchase, you will need to join the discord server to claim the product. You should make sure the server owner is online before buying. You will also receive a step by step guide on how to log into these accounts.")
                setFeatures(['Temporary Access', 'Unbanned from Hypixel', 'Instant Email Delivery', '24/7 Customer Service'])
                const nfaFeatures = document.getElementsByClassName("nfa-features")
                for (let i = 0; i < nfaFeatures.length; i++)
                    nfaFeatures[i].style.display = "block"
            } else if (productNameId.includes("vpn")) {
                setFeatures(['Lifetime Access', 'Lifetime Warranty', 'Instant Email Delivery', '24/7 Customer Service'])
                setInstructions('Upon purchase, you will instantly be sent credentials and instructions regarding how to log into these accounts. Do NOT change any information on the accounts or you may be locked out which will void you from our warranty.')
                const vpnFeatures = document.getElementsByClassName("vpn-features")
                for (let i = 0; i < vpnFeatures.length; i++)
                    vpnFeatures[i].style.display = "block"
            } else if (productNameId === "14d-gamepass-promo") {
                setInstructions('Upon purchase, you will be redirected to the order link where you will view your product. These are not accounts, but promos. You will need to activate them with a payment method.')
                setFeatures(['These are promos to activate 14 day account', 'Debit/Credit Card needed to activate these promos', 'Need USA VPN to activate', 'Instant Email Delivery', '24/7 Customer Service'])
                const gamepassFeatures = document.getElementsByClassName("fourteen-d-gamepass-features")
                for (let i = 0; i < gamepassFeatures.length; i++)
                    gamepassFeatures[i].style.display = "block"
            } else if (productNameId === "skyblock-coins") {
                setFeatures(['Must be at least level 50 on Skyblock.', 'Not Duped/Fake. 100% Authentic and Legit coins.', 'Instant Email Delivery', '24/7 Customer Service'])
                setInstructions('After purchase you will be prompted to join the discord server and provide your in game username which the coins will be transferred to.')
                const skyblockCoinFeatures = document.getElementsByClassName("skyblock-coins-features")
                for (let i = 0; i < skyblockCoinFeatures.length; i++)
                    skyblockCoinFeatures[i].style.display = "block"
            } else if (productNameId === "nitro-three-month") {
                setFeatures(['3 Month Discord Nitro (Not Basic)', '2x Three Month Nitro Boost', 'Account must be at least 30 days old', 'Lifetime Warranty', 'Instant Email Delivery', '24/7 Customer Service'])
                setInstructions('For full information about the promo read Discord Three Month Promo.')
                const nitroFeatures = document.getElementsByClassName("three-month-nitro-features")
                for (let i = 0; i < nitroFeatures.length; i++)
                    nitroFeatures[i].style.display = "block"
            } else if (productNameId === "nitro-1-month") {
                setFeatures(['1 Month Nitro Premium', '2x Server Boost', 'Instant Email Delivery', 'Claim immediately after purchasing'])
                setInstructions('After purchase you will receive an email with the product.')
                const oneMonthFeatures = document.getElementsByClassName("one-month-features")
                for (let i = 0; i < oneMonthFeatures.length; i++)
                    oneMonthFeatures[i].style.display = "block"
            } else if (productNameId === "3month-boost" || productNameId === "1month-boost") {
                setInstructions('After purchase you can claim these boost at your own time.')
                setFeatures(['Fast Delivery', '24/7 Customer Service'])
                const boostFeatures = document.getElementsByClassName("boost-features")
                for (let i = 0; i < boostFeatures.length; i++)
                    boostFeatures[i].style.display = "block"
            } else if (productNameId === "xbox-gamepass-accounts") {
                setInstructions("Upon purchase, you will be redirected to the order link where you will view your product. They will be email:pass and able to log into anywhere. You must set the name of the accounts before playing on some clients.")
                setFeatures(['Full Access', '7 - 14 Day Account Age', 'Instant Email Delivery', '24/7 Customer Service'])
                const xboxGamepassFeatures = document.getElementsByClassName("xbox-gamepass-accounts-features")
                for (let i = 0; i < xboxGamepassFeatures.length; i++)
                    xboxGamepassFeatures[i].style.display = "block"
            }
        }
    }

    useEffect(() => 
    {
        if (window.innerWidth > 1025)
            showElementById('pn-nav-bar');

        onLoad();
    }, [])

    const addToCart = async () => 
    {
        console.log(product.quantity)
        if(orderAmount < product.minimum)
            return addAlert(`Minimum order amount is ${product.minimum}`, "warning", 3000)
        if(orderAmount > product.quantity)
            return addAlert(`Not enough stock to fulfill order`, "warning", 3000)

        const productNameId = window.location.pathname.split("/")[2]

        const shoppingCartItemInfo = {
            productNameId: productNameId,
            quantity: orderAmount
        }

        const response = await addItemToShoppingCart(shoppingCartItemInfo);

        if(response.status === 201)
        {
            showElementById("shopping-cart-added-cont");
            showElementById("shopping-cart-item-added", "flex");

            setShoppingCartItemCount(response.data.itemsCount)
        }else{
            return addAlert("Could not add product to shopping cart", "error", 4000)
        }
    }

    const changeQuantity = (e) => 
    {
        let oAmount = parseInt(orderAmount);

        console.log("ff")
        console.log("Quantity: " + product.quantity)
        console.log("OAmount: " + oAmount)
        console.log("Product Minimum: " + product.minimum)
        if (e.target.id === "i-quantity") {
            if (product.quantity > oAmount)
                oAmount += 1
        } else {
            if (product.minimum < oAmount)
                oAmount -= 1
        }
        setOrderAmount(oAmount)
    }

    const onChange = (e) => {
        setOrderAmount(e.target.value)
    }

    return (
        <div>
            <Helmet>
                <title>{`${product.title}`} | Cheap Minecraft Microsoft Alts</title>
                <meta name="description" content={`Shop and purchase for your ${product.title} from us.`} />
                {product.imageUrl && (
                    <link rel="preload" as="image" href={product.imageUrl} />
                )}
            </Helmet>
            <div className='product-page-cont'>
                <div className="p-p-main">
                    <div className='product-img-cont'>
                        <img src={ShopProducts.get(productNameId).imageUrl} className="product-image" alt={product.description} />
                    </div>
                    <div className="p-p-m-info">
                        <div className="p-p-m-i-cont">
                            <h1 className="p-p-m-header">{product.title}</h1>
                            <ol className="p-p-m-bonus">
                                <li id="permanent-feature" className="fresh-features fa-features">Permanent</li>
                                <li id="permanent-feature" className="vpn-features">Year Warranty</li>
                                <li className="cookie-features">24 Warranty</li>
                                <li id="instant-delivery-feature" className="fresh-features fa-features vpn-features nfa-features fourteen-d-gamepass-features three-month-nitro-features">Instant Delivery</li>
                                <li id="lifetime-warranty-feature" className="fresh-features fa-features three-month-nitro-features">Lifetime Warranty</li>
                                <li id="full-access-feature" className="fresh-features fa-features">Full Access</li>
                            </ol>
                            <article className="p-p-m-description">{product.enhancedDescription}</article>
                        </div>
                        {/* <div className="full-access-addons">                            
                            <div className="fa-addons-options-cont">
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Prename</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Migrator Cape</p>
                                    <p className="fa-addon-option-price">+ $2.00</p>
                                </div>
                                <div className="fa-addons-option">
                                    <p className="fa-addon-option-title">Vanilla Cape</p>
                                    <p className="fa-addon-option-price">+ $4.00</p>
                                </div>
                            </div>
                        </div> */}
                        <div className='s-c-p-prices'>
                            <div className='s-c-p-price-cont'>
                                <div className='s-c-p-price-display'>
                                    <span className='s-c-p-main-price'>
                                        {currency.symbol}{((product.price * currency.rate) * orderAmount).toFixed(2)}
                                    </span>
                                    <span className='s-c-p-dashed-price'>
                                        {currency.symbol}{((product.price * currency.rate) * orderAmount * 1.35).toFixed(2)}
                                    </span>
                                </div>
                                <div className='s-c-p-percent-off'>
                                    - 35%
                                </div>
                            </div>
                            <div className='s-c-p-discount-list'>

                            </div>
                            <div className='s-c-p-rewards'>

                            </div>
                        </div>
                        <div className="button-div">
                            <div className="s-c-p-quantity-setter">
                                <button type="button" className="s-c-p-q-b" id="d-quantity" onClick={changeQuantity}>-</button>
                                <input className="s-c-p-quantity-i" placeholder="1" onChange={onChange} value={orderAmount} type="number" />
                                <button type="button" className="s-c-p-q-b" id="i-quantity" onClick={changeQuantity}>+</button>
                            </div>
                            <button className="purchase" onClick={addToCart}>Add To Cart</button>
                        </div>
                    </div>
                </div>
                <div className="p-p-extra">
                    <div className="p-p-product-details-header">Product Details</div>
                    <div id="product-details" className='p-p-info-mini'>
                        <ProductDetails instructions={instructions} features={features}/>
                    </div>
                    <div className="p-p-product-details-header">Warranty Details</div>
                    <div id="warranty">
                        <div className="p-p-e-item">
                            <p className='p-p-e-item-instructions'>This warranty is for this product and may vary for all other products.</p>
                            <h3 className="p-p-e-item-more-header">Specifications</h3>
                            <ol className="p-p-e-more fourteen-d-gamepass-features">
                                <li>14 days to check and report if product doesn't work.</li>
                            </ol>
                            <ol className="p-p-e-more xbox-gamepass-accounts-features">
                                <li>7 day warranty.</li>
                            </ol>
                            <ol className="p-p-e-more fa-features fresh-features">
                                <li>24 hours to change the email for the product to your own and/or to report any issues doing so.</li>
                                <li>After changing the email you have a lifetime warranty on the product.</li>
                            </ol>
                            <ol className="p-p-e-more one-month-features">
                                <li>24 hours to claim</li>
                                <li>1 month warranty after claiming</li>
                            </ol>
                            <ol className="p-p-e-more boost-features">
                                <li>30 day warranty.</li>
                                <li>If the accounts are kicked due to anti raid on your server, your warranty will be voided. Disable all antispam/raid before purchasing.</li>
                            </ol>
                            <ol className="p-p-e-more three-month-nitro-features">
                                <li>24 hours to claim the promo</li>
                                <li>1 month warranty after promo is claimed</li>
                            </ol>
                            <ol className="p-p-e-more skyblock-coins-features">
                                <li>There is no warranty due to the nature of Hypixel's policy with irl trading.</li>
                                <li>Having high level skyblock account significantly decreased the chance of being banned.</li>
                            </ol>
                            <ol className="p-p-e-more nfa-features">
                                <li>24 hours</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ShoppingCartAdded />
            <ProductSection />
        </div>
    );
}

export default ProductPage;